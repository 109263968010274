/* header .default__header-left {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

Centered title
header .default__header-center {
  flex-grow: 1;
  text-align: center;
  font-size: 20px;
  margin: 0;
} */


.radiobuttons{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 5px;
    font-size: 1rem;
  }
  
  .radio {
    font-weight: 600;
    display: flex;
    align-content: left;
    margin-right: 1rem;
    font-size: 0.95rem;
  
  }
  
  /* .custom_input {
    width: 90%;
    align-content: center
  } */
  
  .requestButton {
    display: flex;
    align-self: center;
    max-width: 30rem;
  }

  select {
    font-size: 1rem;
    width: 90%;
    height: 40px;
    background-color: #e2e2e2;
  }

  