/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light ;
  color: #2e3944;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
}

#root {
  min-height: 100dvh;
  display: grid;
  justify-items: center;
  align-content: start;
  width: 100%;
  background-color: #efefef;
}

p {
  display: flex;
  text-align: center;
  font-size: 1rem;
  border-radius: 15px;
  /* margin: 20px 20px; */
  /* padding: 0.7rem; */
}

h1 {
  color: #1f1f1f;
  font-size: 0.9em;
  line-height: 1.1;
}

/* h2 {
  display: flex;
  justify-content: center;
  font-size: 0.7em;
  color: #215ea9;
  font-weight: bold;
  margin: 1rem 0.3rem;
} */

.centered-flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 25pt;
}

.uiCard {
  display: flex;
  height: fit-content;
  width: calc(100% - 2rem);
  max-width: 768px;
  background-color: white;
  border-radius: 16px;
  margin: 1em 1em 1em;
  padding: 1rem;
  flex-direction: column;
  box-shadow:
  0 10px 20px rgba(0,0,0,0.08),
  0 6px 6px rgba(0,0,0,0.1);
}

.uiCard--full-size {
  max-width: calc(100% - 2rem);
}

@media (min-width: 768px) {
  .uiCard {
    margin-top: 3rem;
  }
}

@media (max-width: 320px) {
  	.formgroup {
      display: flex;
      flex-direction: column;
    }
}

.uiCard--centered {
  align-items: center;
  justify-content: center;
}


/* ------------------ShowVoucher---------------------- */
.voucherInfo {
  border: 3px solid #215ea9;
}




/* ------- */

/* button {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  margin: 1rem 0;
  padding: 1em 1.2em;
  font-size: 1rem;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
  color: #ffffff ;
  background-color: #215ea9;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
} */

button:disabled{
  background-color: #f9f9f9;
  color: #e2e2e2;
  border-width: 3px;
  border-color: #e2e2e2;
}