/* .default__header-left {
  flex: 0 0 auto;
  left: 0
}

.default__header-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
} */


@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: center;
  }
  .header .back-button {
    margin-right: 0;
    order: 1;
  }

  .header .title {
    order: 2;
    margin-top: 10px;
    text-align: center;
  }
}

header .default__header-left {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

/* Centered title */
header .default__header-center {
  flex-grow: 1;
  text-align: center;
  font-size: 20px;
  margin: 0;
}

h2 {
  font-size: 1.2rem;
  /* width: 25rem; */
  /* max-width: 100%; */
}

form {
    display: flex;
    max-width: 40rem;
    /* align-items: center; */
    flex-direction: column;
    /* font-size: 45pt; */
    gap: 1rem;
  }
  
  .formgroup {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;

    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* align-items: center; */
    gap: 5px;
  }
  


  .voucher__alert {
    /* max-width: calc(100% - 2rem); */
    max-width: auto;
    align-self: center;
  }

  .formgroup .custom_input {
    flex: 1;
    font-size: 1rem;
    width: 90%;
    height: 40px;
    /* min-width: 0; */
    background-color: #e2e2e2;
    align-items: right;
    padding: 0.3rem;
    align-content: center

  }
  
  /* .custom_input {
    width: 90%;
    align-content: center
  } */

  .mandatoryInput {
    color: red;
  }
  
  .formgroup label {
    font-size: 1rem;
    font-weight: 600;
    align-items: left;
    align-content: center;
    gap: 3px;
    margin-left: 0.3rem; 
    margin-right: 10px;
    /* white-space: nowrap;  */
  }
  
  

  ScaleLoader{
    color: #215ea9;
    width: 8
  }