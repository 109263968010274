.react-tabs {
    -webkit-tap-highlight-color: transparent;
    font-size: 1.5rem;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: 'troll';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}


table,
th,
td {
    font-size: 0.8rem;
   

}

table {
    /* border-collapse: collapse;
    border-spacing: 0;
    border-width: thin 0 0 thin; */
    margin: 0 0 1em;
    /* table-layout: fixed; */
    width: 100%;
}

th,
td {
    /* width: 100%; */
    /* font-size: 1rem; */
    text-align: center;
/* width: 100%; */
    padding: 0;
}

thead {
    position: sticky;
    top: 0;
    background: white;
}

td {
    /* display: flex; */
    justify-content: center;
    font-weight: 500;
    height: 1rem;
    width: max-content;

}

tr:nth-child(even) td {
    background-color: #e4ebf2;
    color: #000;
}