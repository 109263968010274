.voucher__headline {
  display: flex;
  align-self: center;
  font-size: 1.5rem;
  color: #215ea9;
  font-weight: bold; 
}

.voucher__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 4rem;
  background-color: #ffffff;
  height: 10rem;
}

.bigData {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 5.5rem auto;
    column-gap: 1rem;
    width: 100%;
  }
  
  .voucherData {
    display: inline;
    font-size: 1rem;
    margin: 0.4rem 0;
    /* width: 3rem; */
  }
  
  .dataTitle {
    font-size: 1.2rem;
    font-weight: 700;
    align-self: center;
  }
  
  .label {
    font-weight: bold;
    width: 5rem;
  }
  
  .data {
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    align-self: start;
  }
  
  
  
  .qrcode {
    align-self: center;
    margin: 1rem 0rem;
    width: 65%;
    max-width: 23rem;
  }
  
  .ssid {
    margin: 0;
    padding: 0;
    align-self: center;
    font-weight: bold;
  }
  
  .nextSteps{
    color: black;
    font-weight: bold;
    border: 3px solid #215ea9;
    background-color: #ffffff;
    align-self: center;
    width: 20rem;
    /* gap: 5rem; */
    margin: 2rem 0;
  }
  
  .fa-question-circle {
    font-size: 20pt;
    color: #215ea9; 
  }