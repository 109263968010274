details {
  width: 15rem;
  border-bottom: 1px solid black;
  font-size: 1.5rem;
}

details[open] {
  color: #215ea9;
}

details[open] .content {
  color: black;
}

.fancybox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.instructions {
  width: 15rem;
  padding: 0;
  font-weight: normal;
  text-align: left;
}

img {
  display: block;
  width: 11.5rem;
  height: auto;
}

.accordion{
  align-self: center;
}
