.logo {
    width: 40rem;
    max-width: 100%;
    margin: 1rem;
    padding: 1rem;
}

.home__headline {
    display: flex;
    justify-items: center;
    font-size: 1.5rem;
}

.lang {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.langButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #215ea9;
    border: 3px solid #215ea9;
    padding: 1rem;
    height: 1rem;
}

.footer {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
    gap: 1rem;
}

.footer__start, .footer__end {
    flex-grow: 1;
    min-width: 0;
}

.footer__end {
    text-align: end;
}
